.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.navbar_border_style {
  border-bottom-color: #fd7e14;
  border-bottom-style: solid;
  border-bottom-width: 3px !important;
}

.navbar_background {
  /* background-color:grey; */
  background-color: white;
  opacity:1.0;
}

.navbar_style{
  flex-grow:3;
  margin-left:10px;
  margin-right:10px;
  margin-top:10px;
  margin-bottom:10px;
  display:flex;
  justify-content:space-around;
  align-items:center;
}
